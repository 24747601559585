import v1 from './v1.js';
import v2 from './v2.js';
export const isHexString = (value) => {
    if (typeof value !== 'string' || !value.match(/^0x[0-9A-Fa-f]*$/)) {
        return false;
    }
    return true;
};
function ledger(options) {
    const walletConnectVersion = (options === null || options === void 0 ? void 0 : options.walletConnectVersion) || 2;
    return walletConnectVersion === 1
        ? v1(options)
        : v2(options);
}
export default ledger;
